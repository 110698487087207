import { Side } from "@cur8/api-client";

export type Camera =
  | "a1"
  | "b1"
  | "c1"
  | "a2"
  | "b2"
  | "c2"
  | "a3"
  | "b3"
  | "c3";

export const SIDES: Side[] = ["front", "back"];

export const CAMERAS: Camera[] = [
  "a1",
  "b1",
  "c1",
  "a2",
  "b2",
  "c2",
  "a3",
  "b3",
  "c3",
];
