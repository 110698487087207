import { useRef } from "react";
import { uploadFile } from "lib/upload";
import { useAPIClient } from "render/context/APIContext";
import { APITypes } from "@cur8/api-client";
import { NotificationType } from "./Notification";

interface props {
  children: React.ReactNode;
  patientId: string;
  annotation: APITypes.Annotation | null;
  setNotification: (payload: NotificationType) => void;
}

export default function AddImage({
  children,
  patientId,
  annotation,
  setNotification,
}: props) {
  const api = useAPIClient();
  const ref = useRef<HTMLInputElement>(null);

  async function upload(file: File) {
    let linkedId = "";
    let linkedPid = "";
    if (annotation && annotation.physicalArtefactId) {
      linkedId = annotation.id;
      linkedPid = annotation.physicalArtefactId;
    }

    await uploadFile(
      file,
      patientId,
      linkedId,
      linkedPid,
      api,
      (notification) => {
        setNotification(notification);
      }
    );
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const fileElement = ref.current;

    if (fileElement && fileElement.files) {
      upload(fileElement.files[0]);
    }
  };

  function addImage() {
    if (ref.current) {
      ref.current.click();
    }
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        ref={ref}
        id="file-to-upload"
        type="file"
        accept="image/*"
        capture="environment"
        onChange={handleChange}
        draggable={true}
        multiple={true}
      />
      <div onClick={addImage}>{children}</div>
    </div>
  );
}
