import { useState, useEffect } from "react";
import Logo from "render/components/Logo";
import { makeStyles } from "@material-ui/styles";
import { useMSAL } from "render/context/MSALContext";
import HoverTextButton from "render/components/HoverTextButton";

const useStyles = makeStyles({
  root: {
    "&, &:before, &:after": {
      boxSizing: "box-sizing: border-box",
    },
    width: "100%",
    "& .App": {
      textAlign: "center",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      fontSize: "calc(10px + 2vmin)",
      "& .Header": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "12px",
        "& .UserName": {
          fontSize: "12px",
        },
      },
      "& .Footer": {
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        height: "200px",
        "& .Tools": {
          display: "flex",
          flexDirection: "column",
          width: "100%",
          justifyContent: "space-around",
          "& .item": {
            margin: "8px",
          },
        },
      },
    },
  },
});

export default function MainPage() {
  const classes = useStyles();
  const msal = useMSAL();

  const [userName, setUserName] = useState("");

  function logout() {
    msal.instance.logoutRedirect();
  }

  useEffect(() => {
    const auth = msal.auth;
    if (auth && auth.account) {
      setUserName(auth.account.name ? auth.account.name : "unknown");
    }
  }, [msal]);

  return (
    <div className={classes.root}>
      <div className="App">
        <div className="Header">
          <div className="UserName">Signed in as {userName}</div>
          <div className="Logout">
            <HoverTextButton onClick={logout}>Logout</HoverTextButton>
          </div>
        </div>

        <Logo />

        <div className="Footer">Please Scan QR Code</div>
      </div>
    </div>
  );
}
