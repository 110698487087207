import { APITypes } from "@cur8/api-client";
import { CanvasImage } from "./CanvasImage";
import { AnnotationImage } from "./AnnotationImage";
import { makeStyles } from "@material-ui/styles";
import { Mode } from "../types";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";
import { scrollbars } from "render/css";
import AddImage from "render/components/AddImage";
import { Annotation } from "@cur8/api-client/dist/api/cur8/generated-types";

const useStyles = makeStyles({
  Lesion: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderRadius: "12px",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 100, 0.2), 0 6px 20px 0 rgba(0, 0, 100, 0.19)",
    margin: "16px",
    overflowX: "scroll",
    ...scrollbars("#2B2B59"),
  },
  Plus: {
    display: "flex",
    justifyContent: "center",
    fontSize: "6em",
    width: "20vmin",
    margin: "6px",
    marginRight: "32px",
    "&:hover": {
      color: "grey",
      cursor: "pointer",
    },
  },
});

interface LesionProps {
  patientId: string;
  annotations: APITypes.Annotation[];
  mode: Mode;
  onDelete: (annotation: Annotation) => void;
  onDermaAdded: () => void;
  onFullscreen: (isFullscreen: boolean) => void;
}

export default function Lesion({
  patientId,
  annotations,
  mode,
  onDelete,
  onDermaAdded,
  onFullscreen,
}: LesionProps) {
  const styles = useStyles();
  const [images, setImages] = useState<JSX.Element[]>([]);
  const [firstImage, setFirstImage] = useState<JSX.Element>(<></>);
  const [lastAnno, setLastAnno] = useState<APITypes.Annotation | null>(null);

  useEffect(() => {
    const unlinkedDermaLesions = annotations.filter((a) => {
      return a.applicationSpecificTarget === "derma:unlinked";
    });

    const panoramaAnnotations = annotations
      .filter((a) => {
        return a.applicationSpecificTarget?.startsWith("panorama");
      })
      .sort((a, b) => {
        return a.createdAt < b.createdAt ? -1 : 1;
      });

    if (panoramaAnnotations.length === 0 && unlinkedDermaLesions.length === 0) {
      return;
    }

    let firstImage = <></>;

    if (unlinkedDermaLesions.length > 0) {
      firstImage =
        mode !== "derma" ? (
          <AnnotationImage
            key={unlinkedDermaLesions[0].id}
            anno={unlinkedDermaLesions[0]}
            isOverview={false}
            deletable={true}
            onDelete={() => {
              onDelete(unlinkedDermaLesions[0]);
            }}
            onFullscreen={onFullscreen}
          />
        ) : (
          <></>
        );
    } else {
      firstImage = <CanvasImage anno={panoramaAnnotations[0]} />;
    }

    setFirstImage(firstImage);

    switch (mode) {
      case "derma":
        const dermaAnnotations = annotations
          .filter((a) => {
            return a.applicationSpecificTarget?.startsWith("derma");
          })
          .sort((a, b) => {
            return a.createdAt < b.createdAt ? -1 : 1;
          });

        setImages(
          dermaAnnotations.map((da) => {
            return (
              <AnnotationImage
                key={da.id}
                anno={da}
                isOverview={false}
                deletable={true}
                onDelete={() => {
                  onDelete(da);
                }}
                onFullscreen={onFullscreen}
              />
            );
          })
        );
        break;
      case "macro":
        setImages(
          panoramaAnnotations.map((da) => {
            return (
              <AnnotationImage
                key={da.id}
                anno={da}
                isOverview={false}
                deletable={false}
                onDelete={() => {}}
                onFullscreen={onFullscreen}
              />
            );
          })
        );
        break;
      case "overview":
        setImages(
          panoramaAnnotations.slice(1, panoramaAnnotations.length).map((da) => {
            return <CanvasImage key={da.id} anno={da} />;
          })
        );
    }

    setLastAnno(
      unlinkedDermaLesions.length > 0
        ? unlinkedDermaLesions[unlinkedDermaLesions.length - 1]
        : panoramaAnnotations[panoramaAnnotations.length - 1]
    );
  }, [mode, annotations, onDelete, onFullscreen]);

  const bigPlus = (
    <Tooltip title="Add new Derma image">
      <div className={styles.Plus}>+</div>
    </Tooltip>
  );

  return lastAnno ? (
    <div className={styles.Lesion}>
      {firstImage}
      {images}
      {lastAnno && (
        <AddImage
          patientId={patientId}
          annotation={lastAnno}
          setNotification={() => {
            onDermaAdded();
          }}
        >
          {bigPlus}
        </AddImage>
      )}
    </div>
  ) : null;
}
