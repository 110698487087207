import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  "@keyframes travel": {
    "0%": { left: "-50%", right: "100%" },
    "50%": { left: "20%", right: "20%" },
    "100%": { left: "100%", right: "-50%" },
  },
  LineThrobber: {
    height: "6px",
    position: "relative",
    overflow: "hidden",
    width: "100%",
  },
  indicator: {
    animation: "$travel 1s linear infinite",
    background: "#fff",
    height: "100%",
    position: "absolute",
  },
});

export default function LineThrobber() {
  const classes = useStyles();

  return (
    <div className={classes.LineThrobber}>
      <div className={classes.indicator} />
    </div>
  );
}
