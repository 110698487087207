import "./App.css";

import APIContext from "render/context/APIContext";
import AppInsightsContext from "render/context/AppInsightsContext";
import ConfigContext from "render/context/ConfigContext";
import MSALContext from "render/context/MSALContext";
import NotificationContext from "render/context/NotificationContext";
import MainPage from "pages/MainPage/MainPage";
import LesionPage from "pages/LesionPage/LesionPage";
import AddImagePage from "pages/AddImagePage/AddImagePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <ConfigContext>
      <AppInsightsContext>
        <NotificationContext>
          <MSALContext>
            <APIContext>
              <Router>
                <Routes>
                  <Route path="/" element={<MainPage />} />
                  <Route path="lesions/:patientId" element={<LesionPage />} />
                  <Route path="add/:externalId" element={<AddImagePage />} />
                </Routes>
              </Router>
            </APIContext>
          </MSALContext>
        </NotificationContext>
      </AppInsightsContext>
    </ConfigContext>
  );
}

export default App;
