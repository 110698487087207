import { Annotation } from "@cur8/api-client/dist/api/cur8/generated-types";
import { useAnnotationImage } from "../hooks/useAnnotationImage";
import { makeStyles } from "@material-ui/styles";
import { useState, useEffect, useRef } from "react";
import { APITypes } from "@cur8/api-client";
import { devAtlasUrlFromAnnotation } from "../utils/devAtlasUrlFromAnnotation";
import LineThrobberBusyView from "render/ui/views/LineThrobberBusyView";
import { isDev } from "../utils/isDev";

const useStyles = makeStyles({
  CanvasImage: {
    width: "20vmin",
    margin: "6px",
  },
  DermaFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  CanvasImageFullscreen: {
    display: "block",
    width: "auto",
    height: "auto",
    maxWidth: "95%",
    maxHeight: "95%",
    margin: "10px",
  },
  ImageContainer: {
    display: "flex",
    margin: "4px",
    alignItems: "flex-start",
  },
  ImageContainerFullscreen: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "0",
    backgroundColor: "black",
    zIndex: 100,
  },
  Button: {
    margin: "0px",
  },
  ButtonFullscreen: {
    display: "none",
  },
  Timestamp: {
    fontSize: "12px",
  },
});

interface CanvasImageProps {
  anno: Annotation;
}

export function CanvasImage({ anno }: CanvasImageProps) {
  const classes = useStyles();
  const { image, originalSize } = useAnnotationImage(anno, true);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [style, setStyle] = useState({
    isFullscreen: false,
    image: classes.CanvasImage,
    container: classes.ImageContainer,
    button: classes.Button,
  });

  function imageFullscreenToggle() {
    if (style.isFullscreen) {
      setStyle({
        isFullscreen: false,
        container: classes.ImageContainer,
        image: classes.CanvasImage,
        button: classes.Button,
      });
    } else {
      setStyle({
        isFullscreen: true,
        container: classes.ImageContainerFullscreen,
        image: classes.CanvasImageFullscreen,
        button: classes.ButtonFullscreen,
      });
    }
  }

  function parseTimestamp(anno: Annotation): string {
    // TODO: we really need to store a source image timestamp as part of annotations
    //return anno.createdAt.split("T")[0];
    return anno.targetUri.split(":")[3].split(".")[0];
  }

  useEffect(() => {
    function drawAnnotation(
      canvasRect: DOMRect,
      ctx: CanvasRenderingContext2D
    ): void {
      if (!originalSize || !image) return;
      const scaleW = originalSize.w / image.width;
      const scaleH = originalSize.h / image.height;

      const r = (anno.data as APITypes.AnnotationData2D).rect;
      const ss = ctx.strokeStyle;
      ctx.strokeStyle = "#bbffbb";
      ctx.lineWidth = 3;
      ctx.strokeRect(
        r.left / scaleW,
        r.top / scaleH,
        r.width / scaleW,
        r.height / scaleH
      );
      ctx.stroke();
      ctx.strokeStyle = ss;
    }

    if (canvasRef.current && image) {
      canvasRef.current.width = image.width;
      canvasRef.current.height = image.height;

      const context = canvasRef.current.getContext("2d");
      if (!context) return;

      context.drawImage(
        image,
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height
      );

      drawAnnotation(canvasRef.current.getBoundingClientRect(), context);
    }
  }, [image, anno, originalSize]);

  if (!image) {
    return (
      <div>
        <LineThrobberBusyView />
      </div>
    );
  }

  const timeStamp = (anno: Annotation) => {
    const ts = parseTimestamp(anno);

    return !style.isFullscreen ? (
      <div className={classes.Timestamp}>
        {isDev() ? (
          <a href={devAtlasUrlFromAnnotation(anno)}>{ts}</a>
        ) : (
          <>{ts}</>
        )}
      </div>
    ) : undefined;
  };

  return (
    <div className={style.container}>
      <div className={classes.DermaFlex}>
        <canvas
          className={style.image}
          ref={canvasRef}
          onClick={imageFullscreenToggle}
        ></canvas>
        {timeStamp(anno)}
      </div>
    </div>
  );
}
