import React, { useEffect, useRef } from "react";
import HoverTextButton from "./HoverTextButton";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  HoverTextButtonSelected: {
    "& > button": { background: "#21C2B7" },
  },
});

export default function SelectableHoverTextButton({
  selected,
  children,
  ...props
}: React.ButtonHTMLAttributes<any> & {
  selected: boolean;
}) {
  const classes = useStyles({ clickable: !!props.onClick });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selected) {
      if (ref.current) {
        ref.current.classList.add(classes.HoverTextButtonSelected);
      }
    } else {
      if (ref.current) {
        ref.current.classList.remove(classes.HoverTextButtonSelected);
      }
    }
  }, [selected, classes]);

  return (
    <div ref={ref}>
      <HoverTextButton {...props}>{children}</HoverTextButton>
    </div>
  );
}
