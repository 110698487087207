import { makeStyles } from "@material-ui/styles";
//import LineThrobber from "render/ui/throbber/LineThrobber";
import LineThrobber from "render/ui/throbber/LineThrobber";
const useStyles = makeStyles({
  LineThrobberBusyView: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",

    "& > .container": {
      maxWidth: "50%",
      width: "320px",
    },
  },
});

export default function LineThrobberBusyView() {
  const styles = useStyles();

  return (
    <div className={styles.LineThrobberBusyView}>
      <div className="container">
        <LineThrobber />
      </div>
    </div>
  );
}
