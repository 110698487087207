import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import SelectableHoverTextButton from "../../render/components/SelectableHoverTextButton";
import ButtonSet from "render/components/ButtonSet";
import { useNavigate } from "react-router-dom";
import { useAPIClient } from "render/context/APIContext";
import { APITypes } from "@cur8/api-client";
import { extractLesions } from "./utils/lesionHelpers";
import Lesion from "./components/Lesion";
import { Mode } from "./types";
import { useParams } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import Notification, { NotificationType } from "render/components/Notification";
import PatientInfo from "./components/PatientInfo";
import HoverTextButton from "render/components/HoverTextButton";
import AddImage from "render/components/AddImage";

const useStyles = makeStyles({
  LesionPage: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    overflow: "auto",
    "& .header": {
      display: "flex",
      flexDirection: "column",
      alignItems: "left",
      margin: "6px",
      "& .row": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "None",
        justifyContent: "space-between",
        width: "100%",
        "& .left": {
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        },
        "& .addImage": {
          display: "flex",
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
    "& .noLesions": {
      height: "66vh",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .fullscreen": {
      overflow: "hidden",
    },
  },
});

function parseUri(targetUri: string) {
  const tokens = targetUri.split(":");
  return { fp: tokens.pop() || "", externalId: tokens[2] };
}

export default function LesionPage() {
  const api = useAPIClient();
  const [annotations, setAnnotations] = useState<APITypes.Annotation[]>([]);
  const [mode, setMode] = useState<Mode>("overview");
  const [hasFetched, setHasFetched] = useState(false);

  const lesions = useMemo(() => {
    return extractLesions(annotations);
  }, [annotations]);

  const LesionPageRef = useRef<HTMLDivElement>(null);

  const [notification, setNotification] = useState<NotificationType>({
    type: "success",
    message: "",
  });

  const styles = useStyles();

  const { patientId } = useParams();

  const navigate = useNavigate();

  const refreshAnnotations = useCallback(() => {
    api.annotation
      .queryAnnotations({
        patientId: patientId,
        acceptState: APITypes.AcceptState.Accepted,
        pageSize: 100,
      })
      .result.then((response) => {
        // Sort annotations such that unlinked annotations comes first,
        // then new panorama annotations in descending order such that
        // the first lesion row corresponds to the latest marking made in Dr.UI

        const sorted = response.items.sort((a, b) => {
          if (a.applicationSpecificTarget === "derma:unlinked") {
            if (b.applicationSpecificTarget === "derma:unlinked") {
              return a.createdAt < b.createdAt ? 1 : -1;
            } else {
              return 1;
            }
          } else if (b.applicationSpecificTarget === "derma:unlinked") {
            if (a.applicationSpecificTarget === "derma:unlinked") {
              return a.createdAt < b.createdAt ? 1 : -1;
            } else {
              return 1;
            }
          } else if (
            a.applicationSpecificTarget?.startsWith("panorama") &&
            !b.applicationSpecificTarget?.startsWith("panorama")
          ) {
            return -1;
          } else if (
            !a.applicationSpecificTarget?.startsWith("panorama") &&
            b.applicationSpecificTarget?.startsWith("panorama")
          ) {
            return 1;
          } else {
            return a.createdAt < b.createdAt ? 1 : -1;
          }
        });
        setAnnotations(sorted);
        setHasFetched(true);
      });
  }, [api, patientId]);

  async function findLinkedAnnotation(anno: APITypes.Annotation) {
    return api.annotation
      .queryAnnotations({
        patientId: patientId,
        applicationSpecificTarget: anno.targetUri,
      })
      .result.then((response) => {
        return response.items;
      });
  }

  async function deleteOneAnnotation(anno: APITypes.Annotation) {
    const { externalId } = parseUri(anno.targetUri);
    const result = await api.annotation.deleteAnnotation({
      patientId: externalId,
      annotationId: anno.id,
    }).result;
    if (result.status === 204) {
      setAnnotations(
        annotations.filter((a) => {
          return a.id !== anno.id;
        })
      );
    } else {
      setNotification({
        type: "error",
        message: "Could not delete annotation:" + anno.id,
      });
    }
  }

  async function deleteAnnotation(anno: APITypes.Annotation) {
    deleteOneAnnotation(anno);
    const linkedAnnotations = await findLinkedAnnotation(anno);
    console.log("linked annotations: ", linkedAnnotations);
    linkedAnnotations.forEach((a) => {
      deleteOneAnnotation(a);
    });
    refreshAnnotations();
  }

  useEffect(() => {
    refreshAnnotations();
    return () => {};
  }, [refreshAnnotations]);

  const handleFullscreen = (isFullscreen: boolean) => {
    if (LesionPageRef.current) {
      if (isFullscreen) {
        LesionPageRef.current.classList.add("fullscreen");
      } else {
        LesionPageRef.current.classList.remove("fullscreen");
      }
    }
  };

  const noLesions = (
    <div className="noLesions">
      <h1>Patient has no lesions</h1>
      New lesions are created in DoctorUI
    </div>
  );

  if (!patientId) return <></>;

  return (
    <div className={styles.LesionPage} ref={LesionPageRef}>
      <Notification
        notification={notification}
        setNotification={(notification) => {
          setNotification(notification);
        }}
      />

      <div className="header">
        <div className="row">
          <div className="left">
            <IconButton
              color="primary"
              size="large"
              onClick={() => {
                navigate("/");
              }}
            >
              <Tooltip title="Back to start page">
                <ArrowBack fontSize="inherit" />
              </Tooltip>
            </IconButton>
            <PatientInfo patientId={patientId} />
          </div>
          <ButtonSet>
            <SelectableHoverTextButton
              selected={mode === "overview"}
              onClick={() => {
                setMode("overview");
              }}
            >
              Overview
            </SelectableHoverTextButton>
            <SelectableHoverTextButton
              selected={mode === "macro"}
              onClick={() => {
                setMode("macro");
              }}
            >
              Macro
            </SelectableHoverTextButton>
            <SelectableHoverTextButton
              selected={mode === "derma"}
              onClick={() => {
                setMode("derma");
              }}
            >
              Derma
            </SelectableHoverTextButton>
          </ButtonSet>
        </div>

        <div className="row">
          <div className="addImage">
            <AddImage
              patientId={patientId}
              annotation={null}
              setNotification={setNotification}
            >
              <HoverTextButton>+ Add new lesion</HoverTextButton>
            </AddImage>
          </div>
        </div>
      </div>
      {lesions.length > 0 ? (
        <div>
          {lesions.map((l) => {
            const lannos = annotations.filter(
              (a) => a.physicalArtefactId === l
            );
            return (
              <Lesion
                patientId={patientId}
                annotations={lannos}
                mode={mode}
                onDelete={deleteAnnotation}
                onDermaAdded={() => {
                  refreshAnnotations();
                  setMode("derma");
                }}
                onFullscreen={handleFullscreen}
                key={l}
              />
            );
          })}
        </div>
      ) : (
        hasFetched && noLesions
      )}
    </div>
  );
}
