import { v4 as uuid } from "uuid";
import { APIClient, APITypes } from "@cur8/api-client";
import { PatientBlobURI, AnnotationURI } from "@cur8/uri";
import { NotificationType } from "render/components/Notification";

/**
 * Upload file to patient blob store and create annotation
 *
 * @param file
 * @param patientId
 * @param physicalArtefactId
 * @param api
 * @param notificationCb
 * @returns Promise<APITypes.Annotation | null>
 */

export async function uploadFile(
  file: File,
  patientId: string,
  linkedAnnotationId: string,
  physicalArtefactId: string,
  api: APIClient,
  notificationCb: (payload: NotificationType) => void
) {
  if (!patientId) {
    notificationCb({
      type: "error",
      message: "Has no patientId when trying to upload file",
    });
    return null;
  }

  const filename = file.name.toLowerCase();
  // Check that it is an image file
  if (
    !(
      filename.endsWith(".jpeg") ||
      filename.endsWith(".jpg") ||
      filename.endsWith(".png") ||
      filename.endsWith(".jfif")
    )
  ) {
    notificationCb({
      type: "error",
      message: "Only .jpg, .jpeg, .jfif and .png currently supported",
    });
    return null;
  }
  const ending = filename.split(".").pop();

  const path = `files/${uuid().replace(/-/g, "")}.${ending}`;

  try {
    await api.blob.putPatientBlob({
      patientId,
      file,
      path,
    }).result;
  } catch (error) {
    console.error("Could not post image: ", error);
    notificationCb({
      type: "error",
      message: "Could not upload image",
    });
    return null;
  }

  // create annotation
  const targetUri = new PatientBlobURI(patientId, path);

  const hasPid = physicalArtefactId.length > 0 ? true : false;

  const newAnno = api.annotation
    .createAnnotation(
      { patientId },
      {
        targetUri: targetUri.toString(),
        physicalArtefactId: hasPid ? physicalArtefactId : undefined,
        applicationSpecificTarget: hasPid ? "derma:derma" : "derma:unlinked",
        acceptState: APITypes.AcceptState.Accepted,
      }
    )
    .result.then((annotation) => {
      console.log("created annotation:", annotation);
      notificationCb({
        type: "success",
        message: "Image uploaded",
      });
      return annotation;
    })
    .catch((e) => {
      console.error("Could not create annotation:", e);
      notificationCb({
        type: "error",
        message: "Could not create annotation",
      });
      return null;
    });

  if (linkedAnnotationId && hasPid) {
    // Create linked annotation for DoctorUI to find Derma images
    const annotationUri = new AnnotationURI(patientId, linkedAnnotationId);
    api.annotation
      .createAnnotation(
        { patientId },
        {
          targetUri: annotationUri.toString(),
          physicalArtefactId: physicalArtefactId,
          applicationSpecificTarget: targetUri.toString(),
          acceptState: APITypes.AcceptState.Accepted,
        }
      )
      .result.then((annotation) => {
        console.log("created linked annotation:", annotation);
        notificationCb({
          type: "success",
          message: "Image uploaded",
        });
        return annotation;
      })
      .catch((e) => {
        console.error("Could not create annotation:", e);
        notificationCb({
          type: "error",
          message: "Could not create annotation",
        });
        return null;
      });
  }

  return newAnno;
}
