import { isSide } from "./guard";
import { Side } from "./types";

export class PanoramaImageURI {
  type = "panorama";
  deviceId: string;
  recordingId: string;
  side: Side;
  cameraName: string;

  static fromString(uri: string) {
    const [type, deviceId, recordingId, side, cameraName] = uri
      .split(":")
      .map(decodeURIComponent);

    if (type !== "panorama") {
      throw new Error("Not PanoramaImageURI");
    }

    if (!isSide(side)) {
      throw new Error(`Invalid value for side: ${side}`);
    }

    return new PanoramaImageURI(deviceId, recordingId, side, cameraName);
  }

  static parse(text: string) {
    const [type, deviceId, recordingId, side, cameraName] = text
      .split(":")
      .map(decodeURIComponent);

    if (type !== "panorama") {
      return null;
    }

    if (!isSide(side)) {
      return null;
    }

    return new PanoramaImageURI(deviceId, recordingId, side, cameraName);
  }

  constructor(
    deviceId: string,
    recordingId: string,
    side: Side,
    cameraName: string
  ) {
    this.type = "panorama";
    this.deviceId = deviceId;
    this.recordingId = recordingId;
    this.side = side;
    this.cameraName = cameraName;
  }

  toString(): string {
    return [
      "panorama",
      this.deviceId,
      this.recordingId,
      this.side,
      this.cameraName,
    ]
      .map(encodeURIComponent)
      .join(":");
  }
}
