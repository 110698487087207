import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  ButtonSet: {
    display: "flex",
    gap: "16px",
    gridAutoFlow: "column",
  },
});

interface ButtonSetProps {
  children: React.ReactNode;
}

export default function ButtonSet({ children }: ButtonSetProps) {
  const styles = useStyles();
  return <div className={styles.ButtonSet}>{children}</div>;
}
