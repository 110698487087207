import {
  Annotation,
  MoleClassification,
} from "@cur8/api-client/dist/api/cur8/generated-types";
import { useAnnotationImage } from "../hooks/useAnnotationImage";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import LineThrobberBusyView from "render/ui/views/LineThrobberBusyView";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { Tooltip } from "@mui/material";

const useStyles = makeStyles({
  AnnotationImage: {
    width: "20vmin",
    margin: "6px",
  },
  DermaFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  AnnotationImageFullscreen: {
    display: "block",
    width: "auto",
    height: "auto",
    maxWidth: "95%",
    maxHeight: "95%",
    margin: "10px",
  },
  ImageContainer: {
    display: "flex",
    margin: "4px",
    alignItems: "flex-end",
  },
  ImageContainerFullscreen: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "0",
    backgroundColor: "black",
    zIndex: 100,
  },
  Button: {
    margin: "0px",
  },
  ButtonFullscreen: {
    display: "none",
  },
  Timestamp: {
    fontSize: "12px",
  },
  Footer: {
    width: "100%",
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-around",
  },
  Link: {
    color: "#ffffff",
  },
});

interface AnnotationImageProps {
  anno: Annotation;
  isOverview: boolean;
  deletable: boolean;
  onDelete: () => void;
  onFullscreen: (isFullscreen: boolean) => void;
}

export function AnnotationImage({
  anno,
  isOverview,
  deletable,
  onDelete,
  onFullscreen,
}: AnnotationImageProps) {
  const classes = useStyles();
  const { image } = useAnnotationImage(anno, isOverview);

  const [style, setStyle] = useState({
    isFullscreen: false,
    image: classes.AnnotationImage,
    container: classes.ImageContainer,
    button: classes.Button,
  });

  function imageFullscreenToggle() {
    if (style.isFullscreen) {
      setStyle({
        isFullscreen: false,
        container: classes.ImageContainer,
        image: classes.AnnotationImage,
        button: classes.Button,
      });
      onFullscreen(true);
    } else {
      setStyle({
        isFullscreen: true,
        container: classes.ImageContainerFullscreen,
        image: classes.AnnotationImageFullscreen,
        button: classes.ButtonFullscreen,
      });
      onFullscreen(false);
    }
  }

  function parseTimestamp(anno: Annotation) {
    // TODO: we need to store source timestamp as part of annotations ...
    if (anno.applicationSpecificTarget?.includes("derma")) {
      return anno.createdAt.split(".")[0];
    } else {
      return anno.targetUri.split(":")[3].split(".")[0];
    }
  }

  if (!image) {
    return (
      <div>
        <LineThrobberBusyView />
      </div>
    );
  }

  function timestampLabel() {
    if (style.isFullscreen) return undefined;
    return <div className={classes.Timestamp}>{parseTimestamp(anno)}</div>;
  }

  const size = (anno.classification as MoleClassification)?.size?.toFixed(2);
  const sizeLabel =
    !style.isFullscreen && size ? (
      <div className={classes.Timestamp}>{size + " mm"}</div>
    ) : undefined;

  const annoType = (anno.classification as MoleClassification)?.$type;
  const typeLabel =
    !style.isFullscreen && annoType ? (
      <div className={classes.Timestamp}>{annoType}</div>
    ) : undefined;

  const deleteButton =
    deletable && !style.isFullscreen ? (
      <IconButton color="primary" size="small" onClick={onDelete}>
        <Tooltip title="Delete image">
          <DeleteIcon fontSize="inherit" />
        </Tooltip>
      </IconButton>
    ) : undefined;

  return (
    <div className={style.container}>
      <div className={classes.DermaFlex}>
        <img
          className={style.image}
          src={image.src}
          alt=""
          onClick={imageFullscreenToggle}
        />
        <div className={classes.Footer}>
          {timestampLabel()} {typeLabel} {sizeLabel}
        </div>
      </div>
      {deleteButton}
    </div>
  );
}
