import { PatientDetails } from "@cur8/api-client/dist/api/cur8/generated-types";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

interface props {
  patientId: string;
}

export default function PatientInfo({ patientId }: props) {
  const api = useAPIClient().patient;

  const [patientDetails, setPatientDetails] = useState<PatientDetails>();

  useEffect(() => {
    api.getPatientDetails({ patientId }).result.then((patient) => {
      setPatientDetails(patient);
    });
  }, [api, patientId]);

  let name = "no name";
  const displayName = patientDetails?.name?.displayName;
  const firstName = patientDetails?.name?.firstName;
  const lastName = patientDetails?.name?.lastName;

  const physicalIds: string[] = patientDetails?.physicalIds || [];

  if (displayName) {
    name = displayName;
  } else if (firstName) {
    name = lastName ? firstName + " " + lastName : firstName;
  } else if (
    physicalIds.length > 0 &&
    physicalIds[0].startsWith("cur8:id:legacy:")
  ) {
    name = physicalIds[0].split(":")[3];
  }

  return <div>{name}</div>;
}
