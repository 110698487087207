import { Annotation } from "@cur8/api-client/dist/api/cur8/generated-types";
import { useAnnotationImage } from "render/hooks/useAnnotationImage";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  DUIImage: {
    width: "80vmin",
    margin: "6px",
  },
  DermaFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  DUIImageFullscreen: {
    display: "block",
    width: "auto",
    height: "auto",
    maxWidth: "95%",
    maxHeight: "95%",
    margin: "10px",
  },
  ImageContainer: {
    display: "flex",
    margin: "4px",
    alignItems: "flex-end",
  },
  ImageContainerFullscreen: {
    display: "block",
    position: "absolute",
    top: "0",
    left: "0",
    bottom: "0",
    right: "0",
    margin: "0",
    backgroundColor: "black",
    zIndex: 100,
  },
  Button: {
    margin: "0px",
  },
  ButtonFullscreen: {
    display: "none",
  },
  Timestamp: {
    fontSize: "8px",
  },
});

interface DUIImageProps {
  anno: Annotation;
  isOverview: boolean;
}

export function DUIImage({ anno, isOverview }: DUIImageProps) {
  const classes = useStyles();
  const { image } = useAnnotationImage(anno, isOverview);

  function parseTimestamp(anno: Annotation) {
    return anno.createdAt.split("T")[0];
  }

  if (!image) {
    return (
      <div>
        <p>loading ...</p>
      </div>
    );
  }

  return (
    <div className={classes.ImageContainer}>
      <div className={classes.DermaFlex}>
        <img className={classes.DUIImage} src={image.src} alt="" />
        <div className={classes.Timestamp}>{parseTimestamp(anno)}</div>
      </div>
    </div>
  );
}
