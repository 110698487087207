import { createContext, useContext, useEffect, useState } from "react";
import { Configuration as MSALConfiguration } from "@azure/msal-browser";
import { parseAsJSON } from "lib/parse";

type AppInsights = {
  connectionString: string;
};

type MSALScopes = {
  scopes: {
    login: string[];
    token: string[];
  };
};

type MSAL = MSALConfiguration & MSALScopes;

type Config = {
  apiBaseUrl: string;
  msal: MSAL;
  appInsights: AppInsights;
};

const Context = createContext<Config | null>(null);

interface ConfigContextProps {
  children: React.ReactNode;
}

export default function ConfigContext({ children }: ConfigContextProps) {
  const [config, setConfig] = useState<Config>();

  useEffect(() => {
    fetch("/config.json")
      .then((res) => parseAsJSON<Config>(res))
      .then(setConfig)
      .catch((error) => {
        console.error("Config init failed: %s", error);
      });
  }, []);

  if (!config) {
    return null;
  }

  return <Context.Provider value={config}>{children}</Context.Provider>;
}

export function useConfig() {
  const config = useContext(Context);
  if (!config) {
    throw new Error("useConfig without ConfigContext");
  }
  return config;
}
