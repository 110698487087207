import { createContext, useContext, useMemo } from "react";
import {
  ApplicationInsights,
  DistributedTracingModes,
} from "@microsoft/applicationinsights-web";
import { useConfig } from "./ConfigContext";

const Context = createContext<ApplicationInsights | null>(null);

interface AppInsightsContextProps {
  children: React.ReactNode;
}

export default function AppInsightsContext({
  children,
}: AppInsightsContextProps) {
  const config = useConfig();

  const instance = useMemo(() => {
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: config.appInsights.connectionString,
        distributedTracingMode: DistributedTracingModes.W3C,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: false,
        autoTrackPageVisitTime: false,
      },
    });

    appInsights.loadAppInsights();

    return appInsights;
  }, [config]);

  return <Context.Provider value={instance}>{children}</Context.Provider>;
}

export function useAppInsights() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAppInsights without AppInsightsContext");
  }
  return context;
}
