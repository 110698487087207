import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  HoverTextButton: {
    background: "none",
    border: "1px solid #3F375C",
    borderRadius: "4px",
    color: "#fff",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: 500,
    padding: "8px",
    "&:hover": {
      background: "#504CCA",
      borderColor: "#504CCA",
    },
    "&:disabled": {
      color: "#fff4",
      pointerEvents: "none",
    },
  },
});

export default function HoverTextButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<any>) {
  const classes = useStyles({ clickable: !!props.onClick });

  return (
    <button className={classes.HoverTextButton} type="button" {...props}>
      {children}
    </button>
  );
}
