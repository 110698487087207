import { createContext, useContext, useEffect, useMemo, useState } from "react";
import {
  PublicClientApplication,
  AuthenticationResult,
} from "@azure/msal-browser";
import { useConfig } from "./ConfigContext";

type MSALAuth = {
  instance: PublicClientApplication;
  auth?: AuthenticationResult;
};

const Context = createContext<MSALAuth | null>(null);

interface MSALContextProps {
  children: React.ReactNode;
}

export default function MSALContext({ children }: MSALContextProps) {
  const config = useConfig();

  const instance = useMemo(
    () => new PublicClientApplication(config.msal),
    [config]
  );

  const [auth, setAuth] = useState<AuthenticationResult>();

  useEffect(() => {
    const request = { scopes: config.msal.scopes.token };

    instance
      .handleRedirectPromise()
      .then((authResult) => {
        if (authResult === null) {
          return instance.acquireTokenRedirect(request);
        } else {
          setAuth(authResult);
        }

        // Check if the tokenResponse is null
        // If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
        // If the tokenResponse === null, you are not coming back from an auth redirect.
      })
      .catch((error) => {
        // handle error, either in the library or coming back from the server
      });
  }, [config, instance]);

  const value = useMemo<MSALAuth>(() => ({ instance, auth }), [instance, auth]);

  if (!value.auth) {
    return null;
  }

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function useMSAL() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useMSAL without MSALContext");
  }
  return context;
}
