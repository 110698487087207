import { makeStyles } from "@material-ui/styles";
import { RequestError } from "lib/error/error";

const useStyles = makeStyles({
  RequestErrorNotification: {},
});

interface RequestErrorNotificationProps {
  comment: React.ReactNode;
  error: RequestError;
}

export default function RequestErrorNotification({
  comment,
  error,
}: RequestErrorNotificationProps) {
  const styles = useStyles();
  return (
    <div className={styles.RequestErrorNotification}>
      <div className="comment">{comment}</div>
      <div>
        <b>Status: {error.response.status}</b>
      </div>
      <div>{error.response.statusText}</div>
    </div>
  );
}
