import { APIClient, createClient } from "@cur8/api-client";
import { RequestError } from "lib/error/error";
import { createContext, useCallback, useContext, useMemo } from "react";
import RequestErrorNotification from "render/fragments/notification/RequestErrorNotification";
import { useAppInsights } from "./AppInsightsContext";
import { useConfig } from "./ConfigContext";
import { useMSAL } from "./MSALContext";
import { useNotifications } from "./NotificationContext";

type APIContextState = {
  client?: APIClient;
};

const INITIAL: APIContextState = {
  client: undefined,
};

const Context = createContext<APIContextState>(INITIAL);

interface APIContextProps {
  children: React.ReactNode;
}

export default function APIContext({ children }: APIContextProps) {
  const config = useConfig();
  const { auth, instance } = useMSAL();
  const appInsights = useAppInsights();

  const { emit } = useNotifications();

  const reportError = useCallback(
    (error: any) => {
      appInsights.trackException(error);

      if (error instanceof RequestError) {
        emit(
          <RequestErrorNotification comment="API Error" error={error} />,
          "error"
        );
      } else {
        emit(`API Error: ${error.message}`, "error");
      }
    },
    [appInsights, emit]
  );

  const api = useMemo(() => {
    if (!auth?.account) {
      return {
        client: undefined,
      };
    }

    const request = { account: auth.account, scopes: config.msal.scopes.token };

    const getToken = () => {
      return instance.acquireTokenSilent(request).then((response) => {
        return response.accessToken;
      });
    };

    return {
      client: createClient({
        buildURL: (path) => new URL(`${config.apiBaseUrl}/${path}`),
        getToken,
        onError: reportError,
      }),
    };
  }, [auth, config, instance, reportError]);

  return <Context.Provider value={api}>{children}</Context.Provider>;
}

export function useAPIContext() {
  return useContext(Context);
}

export function useAPIClient() {
  const { client } = useAPIContext();
  if (!client) {
    throw new Error("API Client not initialized");
  }
  return client;
}
