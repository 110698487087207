import { APITypes, Side } from "@cur8/api-client";
import { SIDES } from "./rigg";

const SORT_ORDERS: APITypes.SortOrder[] = [
  APITypes.SortOrder.Asc,
  APITypes.SortOrder.Desc,
];

export function isSide(value: unknown): value is Side {
  return SIDES.includes(value as Side);
}

export function isSortOrder(value: unknown): value is APITypes.SortOrder {
  return SORT_ORDERS.includes(value as APITypes.SortOrder);
}
