export class RequestError extends Error {
  request: Request;
  response: Response;

  constructor(message: string, request: Request, response: Response) {
    super(
      `Request Error: ${message}: (${response.status}) ${response.statusText}`
    );

    this.request = request;
    this.response = response;
  }
}

export function reportError(error: Error) {
  console.error(error);
}
