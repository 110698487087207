import { makeStyles } from "@material-ui/styles";
import Dermlite from "./assets/dermlite.png";

const useStyles = makeStyles({
  Title: {
    fontSize: "32px",
    padding: "12px",
  },
  AppLogo: {
    width: "50vmin",
    height: "50vmin",
    pointerEvents: "none",
  },
  "@keyframes App-logo-spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

export default function MainPage() {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.Title}>Neko Derma</p>
      <div>
        <img src={Dermlite} className={classes.AppLogo} alt="" />
      </div>
    </div>
  );
}
