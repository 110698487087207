export function scrollbars(color: string) {
  return {
    scrollbarColor: `${color} transparent`,
    "scrollbar-width": "thin",

    "&::-webkit-scrollbar": {
      height: "8px",
      width: "8px",
    },

    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },

    "&::-webkit-scrollbar-thumb": {
      background: color,
      borderRadius: "4px",
    },
  };
}
