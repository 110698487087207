import { APIClient } from "@cur8/api-client";

export async function fetchLegacyName(api: APIClient, externalId: string) {
  return api.patient
    .getPatientDetails({ patientId: externalId })
    .result.then((details) => {
      for (const phyid of details.physicalIds) {
        if (phyid.startsWith("cur8:id:legacy:")) {
          return phyid.split(":")[3];
        }
      }
      return null;
    });
}
