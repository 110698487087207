import { APITypes } from "@cur8/api-client";

/** Return unique lesions (physicalArtefactId:s) */
export function extractLesions(annotations: APITypes.Annotation[]) {
  return annotations
    .map((l) => {
      return l.physicalArtefactId || "";
    })
    .filter((val, ind, arr) => val && arr.indexOf(val) === ind);
}
