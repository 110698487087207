import { useState, useEffect } from "react";
import { useAPIClient } from "render/context/APIContext";
import Notification, { NotificationType } from "render/components/Notification";
import { useParams, useLocation } from "react-router-dom";
import { APITypes } from "@cur8/api-client";
import { Person } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import Button from "@mui/material/Button";
import { AddAPhoto } from "@mui/icons-material";
import { scrollbars } from "render/css";
import { fetchLegacyName } from "lib/fetchLegacyName";
import { DUIImage } from "pages/AddImagePage/components/DUIImage";
import AddImage from "render/components/AddImage";

const useStyles = makeStyles({
  PatientPage: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center",
    justifyContent: "flex-start",
    fontSize: "calc(10px + 2vmin)",
    color: "white",
    overflow: "auto",
    ...scrollbars("#2B2B59"),
  },
  ImageContainer: {
    height: "100%",
  },
  NewLesionButton: {
    display: "flex",
    flexDirection: "row",
    flex: "1",
    justifyContent: "flex-end",
    alignItems: "center",
    marginRight: "12px",
  },
  Header: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
  },
});

// TODO: This view relates to the "old" flow, and can likely be removed soon (TM) ...

export default function AddImagePage() {
  const classes = useStyles();
  const api = useAPIClient();
  const { externalId } = useParams();

  const search = useLocation().search;
  const rigAnnoId = new URLSearchParams(search).get("id");

  const [annotation, setAnnotation] = useState<APITypes.Annotation>();
  const [displayName, setDisplayName] = useState("");

  const navigate = useNavigate();

  const [notification, setNotification] = useState({
    type: "success",
    message: "",
  } as NotificationType);

  useEffect(() => {
    if (!externalId) return;

    fetchLegacyName(api, externalId).then((name) => {
      if (name) {
        setDisplayName(name);
      } else {
        setDisplayName(externalId);
      }
    });

    if (!rigAnnoId) {
      console.error("No annotationId");
      return;
    }

    api.annotation
      .getAnnotation({
        patientId: externalId,
        annotationId: rigAnnoId,
      })
      .result.then((a: APITypes.Annotation) => {
        setAnnotation(a);
      })
      .catch(console.error);

    return () => {};
  }, [api.annotation, rigAnnoId, externalId, displayName, api]);

  const image = annotation ? (
    <DUIImage anno={annotation} isOverview={false} />
  ) : undefined;

  return (
    <div className={classes.PatientPage}>
      <Notification
        notification={notification}
        setNotification={setNotification}
      />

      <div className={classes.Header}>
        <IconButton
          color="primary"
          size="large"
          onClick={() => {
            navigate("/");
          }}
        >
          <Tooltip title="Back to start page">
            <Person fontSize="inherit" />
          </Tooltip>
        </IconButton>
        <p>Add image for {displayName}</p>
        <div className={classes.NewLesionButton}>
          {annotation && externalId && (
            <AddImage
              patientId={externalId}
              annotation={annotation}
              setNotification={setNotification}
            >
              <Button variant="contained" startIcon={<AddAPhoto />}>
                Add image
              </Button>
            </AddImage>
          )}
        </div>
      </div>
      <div className={classes.ImageContainer}>{image}</div>
    </div>
  );
}
